import React from 'react';

import SEO from '../../blocks/SEO';
import SignupPage from '../../../modules/signup';
import SimpleLayout from '../../blocks/SimpleLayout/SimpleLayout';

const Checkout = ({ pathContext, location }) => {
  const { plan, special } = pathContext;

  return (
    <SimpleLayout>
      <SEO title="Authentic Evergreen Marketing | Deadline Funnel" />
      <SignupPage special={special} plan={plan} location={location} />
    </SimpleLayout>
  );
}

export default Checkout;
